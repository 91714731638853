@import 'src/app/styles/vars';

@mixin types-table-grid {
  display: grid;
  align-items: center;
  grid-template-columns: 70% 10% 10% auto;
  column-gap: $spacing-2xs;

  @include mobile-down {
    grid-template-columns: 50% 20% 15% auto;
  }
}

.TypesTable__heading {
  margin-bottom: $spacing-2xs;
}

.TypesTable {
  border: 1px solid $color-background-secondary;
  border-radius: $radius-small;
  margin-bottom: $spacing-2xs;
}

.TypesTable__more {
  font-size: $font-size-14;
}

.TypesTable__header {
  @include types-table-grid;
  padding: $spacing-2xs $spacing-s;
  border-radius: $radius-small $radius-small 0 0;
  background-color: $color-background-quaternary;
  border-bottom: 1px solid $color-background-secondary;

  @include mobile-down {
    display: flex;
    justify-content: space-around;
    flex-wrap: wrap;

    > div:first-child {
      flex: 1 0 100%;
      margin-bottom: 4px;
    }

    > div:nth-child(2) {
      margin-left: 50%;
    }
  }
}

.TypesTable__headerActions {
  @include actions-row;

  @include mobile-down {
    flex: 1;
  }
}

.TypesTable__sortFilter {
  min-width: 220px;
}

.TypesTable__headerItem {
  @include text-body-s-regular;
  font-weight: $font-weight-600;
  justify-self: end;

  @include mobile-down {
    font-size: $font-size-12;
  }
}

.TypesTable__row {
  @include types-table-grid;
  padding: $spacing-2xs $spacing-s;
  border-bottom: 1px solid $color-background-secondary;
}

.TypesTable__rowMain {
  @include flex-col;
  overflow-x: hidden;
}

.TypesTable__name {
  text-align: left;
  width: fit-content;
  font-weight: $font-weight-600;

  @include mobile-down {
    font-size: 12px;
  }
}

.TypesTable__rowMainText {
  @include text-caption-m-regular;
  color: $color-text-primary;
  overflow-x: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  padding-right: $spacing-xm;
}

.TypesTable__date {
  @include flex-middle;
  column-gap: 4px;
  color: $color-text-secondary;

  span {
    font-size: $font-size-12;
    font-weight: $font-weight-600;
  }
}

.TypesTable__status {
  width: 16px;
  height: 16px;
  color: darken($color-text-tertiary, 10%);
}

.TypesTable__rowItem {
  justify-self: center;

  @include mobile-up {
    justify-self: end;
  }

  &:last-child {
    justify-self: center;
  }
}

.TypesTable__rowItem_expand {
  margin-left: auto;
}

.TypesTable__expand {
  @include transition(color, opacity);

  width: 20px;
  height: 20px;
  cursor: pointer;
  color: $color-system-link;

  &:hover {
    color: $color-system-link;
  }
}

.TypesTable__row:hover .TypesTable__expand {
  opacity: 1;
}