$spacing-small-4x: 4px;
$spacing-small-3x: 8px;
$spacing-small-2x: 12px;
$spacing-small-x: 16px;
$spacing-small: 24px;
$spacing-medium: 32px;
$spacing-medium-x: 40px;
$spacing-large: 48px;
$spacing-large-x: 64px;
$spacing-large-2x: 72px;
$spacing-large-3x: 96px;
$spacing-large-4x: 124px;

$spacing-4xs: 2px;
$spacing-3xs: 4px;
$spacing-2xs: 8px;
$spacing-xs: 12px;
$spacing-s: 16px;
$spacing-2xm: 24px;
$spacing-xm: 32px;
$spacing-m: 40px;
$spacing-l: 48px;
$spacing-xl: 56px;
$spacing-2xl: 64px;
$spacing-3xl: 72px;
$spacing-4xl: 96px;
$spacing-5xl: 124px;
