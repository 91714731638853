@import 'colors';
@import 'baseText';
@import 'breakpoints';
@import 'mixins';
@import 'radius';
@import 'spacing';
@import 'z-index';
@import 'shadow';

//$header-height: 64px;

$header-height: 80px;
$footer-height: 72px;