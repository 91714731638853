@import 'src/app/styles/vars';

.Link {
  @include reset-button;
  @include transition(border-color, color);

  position: relative;
  cursor: pointer;

  &:hover,
  &:active,
  &:focus {
    text-decoration: none;
    outline: none;
  }
}

.Link_disabled {
  cursor: default;
}

.Link_standalone {
  &.Link_size_large {
    @include text-body-l-regular;
  }

  &.Link_size_medium {
    @include text-body-m-regular;
  }

  &.Link_size_small {
    @include text-body-s-regular;
  }
}

.Link_variant_primary {
  color: $color-accent-blue-200;

  &.Link_hover:not(.Link_disabled),
  &:hover:not(.Link_disabled, :active) {
    color: darken($color-accent-blue-200, 10%);
  }

  &:active {
    color: darken($color-accent-blue-200, 15%);
  }

  &.Link_disabled {
    color: $color-brand-disabled;
  }
}
//
//.Link_variant_primary {
//  color: $color-button-secondary-default-fill;
//
//  &.Link_hover:not(.Link_disabled),
//  &:hover:not(.Link_disabled, :active) {
//    color: $color-button-secondary-hover-fill;
//  }
//
//  &:active {
//    color: $color-button-secondary-pressed-fill;
//  }
//
//  &.Link_disabled {
//    color: $color-button-secondary-disabled-fill;
//  }
//}

.Link_underlined {
  border-bottom: 1px solid currentcolor;
}
