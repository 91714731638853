@import 'src/app/styles/vars';

.Input {
  @include text-body-m-regular;
  @include transition(border-color);

  position: relative;
  display: flex;
  flex-direction: row;
  align-items: center;
  min-height: 40px;
  padding: $spacing-small-x $spacing-small-x;
  color: $color-text-primary;
  overflow: hidden;
  //border: 1px solid $color-border-default;
  border: 1px solid $color-divider-dark;
  border-radius: $radius-small;
  background-color: $color-background-primary;
  cursor: text;
  box-sizing: border-box;
  overflow-y: auto;
  word-wrap: break-word;
  font-size: $font-size-12;

  &:not(.Input_disabled):hover {
    border-color: $color-system-link-hover;
  }
}

.Input_error {
  border-color: $color-system-error;
}

.Input_focus {
  border-color: $color-system-link-hover;
}

.Input_disabled {
  color: $color-text-tertiary;
  border-color: transparent;
  background: $color-background-secondary;
  cursor: default;

  &:hover {
    box-shadow: none;
  }
}

.Input__input {
  @include reset-default-input;
  @include remove-autofill-style;
  @include hide-scrollbar;

  position: relative;
  flex-grow: 1;
  min-width: 0;
  height: 100%;

  &:placeholder-shown + .Input__clear {
    display: none;
  }

  &[type='number'] {
    appearance: textfield;
  }

  &::-webkit-outer-spin-button,
  &::-webkit-inner-spin-button {
    appearance: none;
  }

  &:-webkit-autofill,
  &:-webkit-autofill:hover,
  &:-webkit-autofill:focus,
  &:-webkit-autofill:active {
    caret-color: $color-text-primary;
    -webkit-text-fill-color: $color-text-primary;
  }
}

.Input__input_disabled {
  cursor: default;
}

.Input__input::placeholder {
  color: $color-text-secondary;
  opacity: 1;
}

.Input__icon {
  display: flex;
  flex: 0 0 auto;
  align-items: center;
  max-height: 100%;
  color: $color-on-surface-light-300;
}

.Input__icon_active {
  @include transition(color);

  cursor: pointer;

  &:hover {
    color: $color-on-surface-light-200;
  }
}

.Input__icon_left {
  justify-content: flex-start;
  margin-right: $spacing-small-3x;
  margin-left: -$spacing-small-3x;
}

.Input__icon_right {
  justify-content: flex-end;
  margin-left: $spacing-small-3x;

  &:last-child {
    margin-right: -$spacing-small-3x;
  }
}

.Input__icon_clear {
  @include transition(color);

  position: relative;
  cursor: pointer;

  &:hover {
    color: $color-on-surface-light-200;
  }
}

.Input_disabled .Input__icon_clear {
  pointer-events: none;
}

.Input__right {

}