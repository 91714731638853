@import 'src/app/styles/vars';

.ModalBody {
  //@include scrollbar;

  //overflow-y: auto;

  @include media-down(tablet-small) {
    border-radius: 0 0 $radius-small $radius-small;
    @include text-body-s-regular;
  }
}
