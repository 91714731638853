@import 'src/app/styles/vars';

.LastActivityTimelineChart {
  width: 100%;
}

.LastActivityTimelineChart__header {
  @include actions-row;
}

.LastActivityTimelineChart__content {
  height: 100%;
  min-height: 500px;
}

.LastActivityTimelineChart__reset {
  font-size: $font-size-16 !important;
  font-weight: $font-weight-600 !important;
  line-height: 10px;
}