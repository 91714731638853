@import 'src/app/styles/vars';

.FilterInput {
  @include flex-col;
}

.FilterInput__input {
  min-width: 100px;
  width: fit-content;
  min-height: 26px !important;
  padding: 0 8px !important;
}

.FilterInput__label {
  @include text-caption-m-regular;
  font-size: $font-size-12;
}