$breakpoint-mobile-small: 320px;
$breakpoint-mobile-large: 375px;
$breakpoint-tablet-small: 768px;
$breakpoint-tablet-large: 1024px;
$breakpoint-desktop-small: 1280px;
$breakpoint-desktop-medium: 1440px;
$breakpoint-desktop-large: 1920px;

@mixin media-up($breakpoint) {
  @if $breakpoint == mobile-small {
    @media screen and (min-width: $breakpoint-mobile-small) {
      @content;
    }
  } @else if $breakpoint == mobile-large {
    @media screen and (min-width: $breakpoint-mobile-large) {
      @content;
    }
  } @else if $breakpoint == tablet-small {
    @media screen and (min-width: $breakpoint-tablet-small) {
      @content;
    }
  } @else if $breakpoint == tablet-large {
    @media screen and (min-width: $breakpoint-tablet-large) {
      @content;
    }
  } @else if $breakpoint == desktop-small {
    @media screen and (min-width: $breakpoint-desktop-small) {
      @content;
    }
  } @else if $breakpoint == desktop-medium {
    @media screen and (min-width: $breakpoint-desktop-medium) {
      @content;
    }
  } @else if $breakpoint == desktop-large {
    @media screen and (min-width: $breakpoint-desktop-large) {
      @content;
    }
  }
}

@mixin media-down($breakpoint) {
  @if $breakpoint == mobile-small {
    @media screen and (max-width: $breakpoint-mobile-small - 1) {
      @content;
    }
  } @else if $breakpoint == mobile-large {
    @media screen and (max-width: $breakpoint-mobile-large - 1) {
      @content;
    }
  } @else if $breakpoint == tablet-small {
    @media screen and (max-width: $breakpoint-tablet-small - 1) {
      @content;
    }
  } @else if $breakpoint == tablet-large {
    @media screen and (max-width: $breakpoint-tablet-large - 1) {
      @content;
    }
  } @else if $breakpoint == desktop-small {
    @media screen and (max-width: $breakpoint-desktop-small - 1) {
      @content;
    }
  } @else if $breakpoint == desktop-medium {
    @media screen and (max-width: $breakpoint-desktop-medium - 1) {
      @content;
    }
  } @else if $breakpoint == desktop-large {
    @media screen and (max-width: $breakpoint-desktop-large - 1) {
      @content;
    }
  }
}

@mixin grid-for($breakpoint) {
  display: grid;

  @if $breakpoint == mobile-small {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    padding-right: 16px;
    padding-left: 16px;
    column-gap: 16px;
  } @else if $breakpoint == mobile-large {
    grid-template-columns: repeat(4, 1fr);
    padding-right: 16px;
    padding-left: 16px;
    column-gap: 16px;
  } @else if $breakpoint == tablet-small {
    grid-template-columns: repeat(8, 1fr);
    padding-right: 72px;
    padding-left: 72px;
    column-gap: 16px;
  } @else if $breakpoint == tablet-large {
    grid-template-columns: repeat(12, 1fr);
    padding-right: 40px;
    padding-left: 40px;
    column-gap: 16px;
  } @else if $breakpoint == desktop-small {
    grid-template-columns: repeat(12, 1fr);
    padding-right: 76px;
    padding-left: 76px;
    column-gap: 24px;
  } @else if $breakpoint == desktop-medium {
    grid-template-columns: repeat(12, 1fr);
    padding-right: 112px;
    padding-left: 112px;
    column-gap: 32px;
  } @else if $breakpoint == desktop-large {
    grid-template-columns: repeat(16, 1fr);
    padding-right: 144px;
    padding-left: 144px;
    column-gap: 32px;
  }
}

@mixin mobile-down {
  @include media-down(tablet-small) {
    @content;
  }
}

@mixin mobile-up {
  @include media-up(tablet-small) {
    @content;
  }
}

@mixin tablet-down {
  @include media-down(desktop-small) {
    @content;
  }
}

@mixin tablet-up {
  @include media-up(desktop-small) {
    @content;
  }
}