@use 'sass:color' as color;

// бело-коричневый
//$color-text: #1b1918;
//$color-background: #fafafa;
//$color-primary: #7e716d;
//$color-secondary: #e7e5e4;
//$color-accent: #887b77;

// синий
$color-text: #010819;
//$color-background: #e6edfe;
//$color-background: #fafafa;
$color-background: #ffffff;
$color-primary: #e44d2e;
//$color-primary: #0647e0;;
$color-secondary: #e5e5ea;
$color-accent: #db2b21;
//$color-accent: #064ff9;

// сине-пастельный
//$color-text: #130e07;
//$color-background: #fbf8f4;
//$color-primary: #223a5e;
//$color-secondary: #ebdcc7;
//$color-accent: #3d68a9;

// синий монохром
//$color-text: #01062d;
//$color-background: #ebedfe;
//$color-primary: #3e51f9;
//$color-secondary: #b5bcfd;
//$color-accent: #071cdf;

$color-light-system-warning-40: #fecc9a;

$color-background-primary: $color-background;
$color-background-secondary: $color-secondary;
$color-background-tertiary: lighten(invert($color-background), 20%);
$color-background-quaternary: rgb(250, 249, 251);
$color-background-dark-100: darken($color-background, 3%);
$color-background-dark-200: darken($color-background, 6%);
$color-background-dark-300: darken($color-background, 12%);
$color-background-dark-400: darken($color-background, 24%);
$color-background-dark-500: darken($color-background, 48%);

$color-background-accent: $color-primary;
$color-background-accent-light: color.adjust($color-primary, $saturation: -30%, $lightness: 30%);
$color-background-accent-100: color.adjust($color-accent, $saturation: -40%, $lightness: 40%);
$color-background-accent-200: color.adjust($color-accent, $saturation: -30%, $lightness: 30%);
$color-background-accent-300: color.adjust($color-accent, $saturation: -20%, $lightness: 20%);
$color-background-accent-400: color.adjust($color-accent, $saturation: -10%, $lightness: 10%);
$color-background-accent-500: $color-background-accent;

:root {
  --accent-light: #{$color-background-accent-light};
}

$color-text-primary: $color-text;
$color-text-secondary: darken($color-background, 40%);
$color-text-tertiary: darken($color-background, 60%);
$color-text-invert: $color-background;
$color-text-brand: $color-primary;
$color-text-disabled: color.adjust($color-text, $lightness: 40%);

$color-brand-primary: $color-primary;
$color-brand-hover: darken($color-brand-primary, 3%);
$color-brand-active: darken($color-brand-primary, 6%);
$color-brand-disabled: color.adjust($color-brand-primary, $lightness: 30%);

$color-border-default: $color-background-dark-300;
$color-border-hover: lighten($color-brand-primary, 30%);
$color-border-active: $color-brand-primary;

$color-divider-dark: $color-border-default;
$color-divider-darker: darken($color-border-default, 10%);

// Button
$color-button-primary-default-fill: $color-brand-primary;
$color-button-primary-hover-fill: $color-brand-hover;
$color-button-primary-pressed-fill: $color-brand-active;
$color-button-primary-disabled-fill: $color-brand-disabled;

$color-button-secondary-default-fill: $color-background-secondary;
$color-button-secondary-hover-fill: darken($color-secondary, 3%);
$color-button-secondary-pressed-fill: darken($color-secondary, 6%);
$color-button-secondary-disabled-fill: lighten($color-secondary, 3%);

//@debug $color-border;

//
/* --- surface --- */
$color-surface-primary: #141414;

/* --- on-surface --- */
$color-on-surface-dark-100: #1d1d1d;
$color-on-surface-dark-200: #252525;
$color-on-surface-dark-300: #2d2d2d;
$color-on-surface-dark-400: #3e3e3e;
$color-on-surface-light-400: #5e6166;
$color-on-surface-light-300: #868a92;
$color-on-surface-light-200: #c5c6ca;
$color-on-surface-light-100: #fafafa;
$color-on-surface-quaternary: #2b2b2b;

/* --- text --- */
//$color-text-primary: #fafafa;
//$color-text-secondary: #c5c6ca;
//$color-text-tertiary: #868a92;
//$color-text-quaternary: #5e6166;
//$color-text-dark: #2b2b2b;
//$color-text-brand: #8e85e5;

/* --- brand --- */
//$color-brand-primary: #695fcf;
//$color-brand-hover: #9288f8;
//$color-brand-disabled: #49428e;

/* --- system --- */
$color-system-link: #7f9ef3;
$color-system-success: #10b981;
$color-system-warning: #f59e0b;
$color-system-error: #f43f5e;
$color-system-link-weak: #142144;
$color-system-success-weak: #042c1f;
$color-system-warning-weak: #3d2907;
$color-system-error-weak: #2d1015;
$color-system-link-hover: #a8c1f8;

/* --- accent --- */
$color-accent-blue-100: #7f9ef3;
$color-accent-blue-200: #2b4acb;
$color-accent-blue-300: #203175;
$color-accent-blue-400: #191e40;
$color-accent-pink-100: #f37fb7;
$color-accent-pink-200: #cb2b83;
$color-accent-pink-300: #75204f;
$color-accent-pink-400: #40162f;
$color-accent-purple-100: #ab7ae0;
$color-accent-purple-200: #642ab5;
$color-accent-purple-300: #3e2069;
$color-accent-purple-400: #21183b;
$color-accent-green-100: #8fd460;
$color-accent-green-200: #49aa19;
$color-accent-green-300: #306317;
$color-accent-green-400: #1d3712;
$color-accent-cyan-100: #58d1c9;
$color-accent-cyan-200: #13a8a8;
$color-accent-cyan-300: #146262;
$color-accent-cyan-400: #163b2e;
$color-accent-orange-100: #f3b765;
$color-accent-orange-200: #d87a16;
$color-accent-orange-300: #7c4a15;
$color-accent-orange-400: #442a11;
$color-accent-yellow-100: #f3ea62;
$color-accent-yellow-200: #d8bd14;
$color-accent-yellow-300: #7c6e14;
$color-accent-yellow-400: #443611;
$color-accent-red-100: #f37370;
$color-accent-red-200: #d32029;
$color-accent-red-300: #791a1f;
$color-accent-red-400: #421716;
//
//$text: #131513;
//$background: #f4f5f4;
//$primary: #5d555e;
//$secondary: #d8d4d6;
//$accent: #706b76;