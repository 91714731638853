@import 'src/app/styles/vars';

.Loader {
  --loader-size: 20px;
  display: flex;
  align-items: center;
  width: var(--loader-size);
  height: var(--loader-size);
  color: $color-on-surface-dark-100;
}

.LoaderIcon {
  width: 100%;
  height: 100%;
  animation: spinner 0.7s linear infinite;
}

@keyframes spinner {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}