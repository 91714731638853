@import 'src/app/styles/vars';

@mixin types-table-grid {
  display: grid;
  align-items: center;
  grid-template-columns: 70% 10% 10% auto;
  column-gap: $spacing-2xs;

  @include mobile-down {
    grid-template-columns: 60% 15% 15% auto;

  }
}

.ManagePage {
  padding: $spacing-xm;
}

.ManagePage__header {
  @include flex;
  align-items: flex-end;
  flex-wrap: wrap;
  column-gap: $spacing-2xm;
  row-gap: $spacing-2xs;
  margin-bottom: $spacing-s;
}

.ManagePage__checkbox {
  margin-bottom: 4px;
}

.ManagePage__heading {
  margin-bottom: $spacing-2xs;
}

.ManagePage__sortFilter {
  min-width: 200px !important;
}

.ManagePage__list {
  @include flex-col;
  row-gap: $spacing-s;
}

.ManagePage__row {
  @include transition(background-color);

  @include types-table-grid;
  padding: $spacing-2xs $spacing-s;
  border: 1px solid $color-background-secondary;
  border-radius: $radius-small;
  max-width: 735px;

  &:hover {
    background-color: $color-background-secondary;
  }
}

.ManagePage__rowMain {
  @include flex-col;
  overflow-x: hidden;
}

.ManagePage__name {
  text-align: left;
  width: fit-content;
  font-weight: $font-weight-600;
}

.ManagePage__rowMainText {
  @include text-caption-m-regular;
  color: $color-text-primary;
  overflow-x: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  padding-right: $spacing-xm;
}

.ManagePage__date {
  @include flex-middle;
  column-gap: 4px;
  color: $color-text-secondary;

  span {
    font-size: $font-size-12;
    font-weight: $font-weight-600;
  }
}

.ManagePage__status {
  width: 16px;
  height: 16px;
  color: $color-text-tertiary;
}

.ManagePage__rowItem {
  justify-self: end;

  > span:first-child {
    font-size: $font-size-12;
    //display: block;
    font-weight: $font-weight-600;
  }

  &:last-child {
    justify-self: center;
  }
}

.ManagePage__expand {
  @include transition(color, opacity);

  width: 20px;
  height: 20px;
  cursor: pointer;
  color: $color-system-link;

  &:hover {
    color: $color-system-link;
  }
}

.ManagePage__row:hover .ManagePage__expand {
  opacity: 1;
}